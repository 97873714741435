:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important
  }
}

// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

// pagination css for all pages
@media (max-width: 768px) {
  .pagin-div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}

@media (min-width:768px) {
  .pagin-div {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    //margin: 15px 17px -18px 14px;
  }
}




.search_bar {
  display: flex;
  justify-content: space-between;
  margin: 15px 17px -18px 14px;
}

.btn-addbtn {
  padding: 6px 30px !important;
}

//searchbar 
@media (max-width: 768px) {
  .search_bar {
    display: flex;
    flex-direction: column;
  }

  .search-inner {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .theform_text {
    font-size: 14px;
  }

  .marMobile {
    margin-bottom: 20px;
  }
}

@media (min-width:768px) {
  .search_bar {
    display: flex;
    justify-content: space-between;
    margin: 15px 17px -18px 14px;
  }

  .search-inner {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    justify-content: space-between;
  }
}

.ck.ck-balloon-panel.ck-powered-by-balloon[class*=position_border] {
  display: none;
}