// Here you can add other styles


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

// tooltip
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    opacity: 0.8;
    z-index: 1;
    display: none;
}

.tooltip-container:hover .tooltip {
    display: block;
}

// hover icon
.viewIcon:hover {
    text-decoration: none;
    color: #7ED50E;
}

.updateIcon:hover {
    text-decoration: none;
    color: #efef0f;
}

.deleteIcon:hover {
    text-decoration: none;
    color: #e81a1a;
}

.table {
    margin-bottom: 0rem !important;
}

.ck.ck-editor {
    padding: 9px !important;
    width: 100% !important;
}

.align_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        font-weight: 400;
    }
}

.logo_bg {
    // background-color: red;
    padding: 10px;
}


.bg-img {
    background-image: url('../../../../src/assets/images/law2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.flex-prop {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.dflex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.red-star {
    color: rgb(255, 0, 0) !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.purpleRow {
    background-color: #0b131e !important;
    color: #fff !important;
}

.custom-table-border {
    border: 1px solid #0b131e !important;

    th {
        border: 1px solid white !important;
    }

}

.row-hover {
    --bs-table-hover-bg: #f9c950b3 !important;
}

.form-header {
    font-weight: bold;
    background: #0b131e !important;
    color: #fff !important
}